import React, { lazy, Suspense } from 'react';

//import { getEnvMap } from './envVarMgr.js';
import dataUtil from '../server/dataUtil.js';
import logit from '../server/logit.js';

export { siteData };
const siteData = (getEnvVarJson, parms) => {
  let getEnvVar = getEnvVarJson;
  let { type, pagePath = '/' } = parms;
  let seoPageData = {};

  //console.log('siteData: dump params', parms);
  // usage:
  // Get routes - THIS HAD PROBLEMS TO CONSOLIDATE and hence it's still in its own file: src/routeList
  //     MAY WANT TO TRY GENERATING THE LIST inside src/routeList from this file
  //     const routes = siteData({ type: 'routeList' });
  //
  // get navigation options (headers/footers)
  //     return siteData({ type: 'headerOptions' });
  //     return siteData({ type: 'footerOptions' });
  //
  // get seoData   
  //     seoPageData: {caller, frontmatter = {}, staticDataLoadResp = null, slug = null }
  //
  // get serverData TODO
  //     

  //
  // Unable to support routeList with imports for '*.mdx' files as it complains about unable to load '.mdx' files.
  // This happens loc:devnode and loc:devvite.
  // So, we keep it in a separate file. src/routeList.js
  /*
  const routeList = [
    { path: "/",                    import: () => import("../pages/Home") , module: "../pages/Home"},
    ....
  ]
  */
  /*
  if (!getEnvVar) {
   getEnvVar = getEnvMap();
  }
  */

  let headerOptions;
  let footerOptions;
  if (type == 'headerOptions' || type == 'footerOptions') {
    // draft: true|false
    // type: page|mdx|ext|     
    // use:  hdr|bgr|dd|ftr
    // auth: y|n
    // path can also be link to a section within a page: path: '/somepage/#section
    const headerPaths = [
      //{ display: 'Home',             path: '/',                  draft: false, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },
//      { display: 'Blog',               path: '/blog',              draft: true,  type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

      { display: 'About',              path: '/about',             draft: true, type: 'page', use: ['hdr','bgr'],      auth: ['y','n'] },

      { display: 'divider',              path: '',                              draft: false, type: 'page', use: ['bgr'],       auth: ['y','n'] },

      { display: 'My Account',              path: '/myaccount',                      draft: false, type: 'page', use: ['hdr','bgr'],  auth: ['y','n'] },
    ];

    const footerPaths = [
      { display: 'About',              path: '/about',             draft: true, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Privacy',            path: '/privacy',           draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Terms & Conditions', path: '/termsandconditions', draft: false, type: 'mdx', use: ['ftr','bgr'],      auth: ['y', 'n'] },
      { display: 'Contact Us',         path:getEnvVar.contactUsUrl,draft: false, type: 'ext', use: ['ftr','bgr'],      auth: ['y', 'n'] },
    ];

    headerOptions = {
      appLogoFilePath: getEnvVar.APP_LOGO_FILE_PATH,
      logoWidth:  48, // 159,  // 175,   // manually set
      logoHeight: 48,  // 63,   // 70,   // manually set
      logoText: getEnvVar.LOGO_TEXT,
      logoTextColor: '#ff0000',
      logoTextType: 'boxed',   // 'plain' | 'rainbow' 
      logoFont: 'Neue',
      logoRainbowColor: '#ff0000',
      appLogoHref: '/',
      headerPaths: headerPaths,
      activeStyle: '2px solid red',
      inactiveStyle: '2px solid transparent',
      navBarHeightOffset: '56px',   // Due to "null " text just after <body> tag. Don't know where it's from
    };

    footerOptions = {
      footerPaths: footerPaths,
      hoverTextColor: '#ff0000',
    }

    /* Future usage for page context menu - social share - will it work in iPad?

       const menuPaths = [
       'Context Menu', 'A', 'B'
       ];

       menuOptions = {
       menuPaths: menuPaths,
       }
     */
  }

  //
  // routes needing data
  //
  // note: All listed routes' component requires code change - access allSrvrData
  if (type == 'routesNeedingData') {
    //return ['/', '/recent', '/blog'];
    return [];
  }

  //
  // routes needing Adverts
  //
  if (type == 'routesAdSpecs') {
    //const Adverts = lazy(() => import('../area/Adverts'));
    return [
      /*
      { page: '/recall',
	ad: Adverts,
	options: { left: false, right: true, bottom: false }
      },
      */
    ];
  }
  
  if (type == 'seoData' && pagePath) {
    let title, description, type, path, keywords;

    keywords = ['custom', 'software', 'react'];

    /*
       // Strip out '/pages' from '/pages/xxx'
       // doesn't work for the error page: '/renderer/_error'
       const callerWOPages = caller.substr(6);
       const pos = callerWOPages.lastIndexOf('/')
       let callerWOIndex = callerWOPages;
       if (pos !== -1) {
       callerWOIndex = callerWOPages.substr(0, pos);
       }
     */

    //console.log('seoSitePageData pagePath ', pagePath);
    //console.log('seoSitePageData pagePath', pagePath);
    //console.log('seoSitePageData emert', emert);

    /* A Template for seoPageData can be reviewed at recalls/src/data/seoSitePageData.js
     */

    switch (pagePath) {

    case '/index':
    case '/':
      seoPageData = {
	//type: 'WebIndexPage',
	type: 'AppIndexPage', //constants.PAGE_TYPE_APPINDEX,
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Form Launcher - Build and share your own online forms with your community to help you capture data at source.',
	keywords: keywords,
	path: pagePath,
	slogan: '',
	title: 'Form Launcher App',
      };
      break;
    case '/privacy':
      seoPageData = {
	type: 'WebPage', //constants.PAGE_TYPE_WEB,
	personseocode: 'principal',
	datemodified: '',
	datepublished: '',
	description: 'website privacy policy',
	keywords: ['forms', 'html', 'builder'],
	path: pagePath,
	slogan: '',
	title: 'website privacy policy',
      };
      break;
    case '/termsandconditions':
      seoPageData = {
	type: 'WebPage',  //constants.PAGE_TYPE_WEB,
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Our simple and privacy friendly terms and conditions.',
	keywords: ['forms', 'html', 'builder'],
	path: pagePath,
	slogan: '',
	title: 'Website terms and conditions policy',
      };
      break;

    case '/fourofour':
      seoPageData = {
	type: 'WebPage', //constants.PAGE_TYPE_WEB,
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Sorry. The page you are looking for is not found. It may have moved!',
	keywords: ['forms', 'html', 'builder'],
	path: pagePath,
	slogan: '',
	title: '404 - Page not found.',
      };
      break;
    case '/myaccount':
      seoPageData = {
	type: 'WebPage',
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description: 'Configure and manage your account, plans and personal preferences',
	keywords: ['signin', 'signout', 'plans', 'preferences'],
	path: pagePath,
	slogan: '',
	title: 'My Account: Manage access, plans and preferences',
      };
      break;

    default:
      seoPageData = {
	type: 'WebPage',  //constants.PAGE_TYPE_WEB,
	personSeoCode: 'principal',
	dateModified: '',
	datePublished: '',
	description:
	  'This page has no description',
        keywords: ['forms', 'html', 'builder'],
	path: pagePath,
	slogan: '',
	title: 'This page has no title',
      };
      break;
      /*
	 case '/about':
	 seoPageData = {
	 type: 'WebPage', //constants.PAGE_TYPE_WEB,
	 personSeoCode: 'principal',
	 dateModified: '',
	 datePublished: '',
	 description: 'Some description ...',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'Some title',
	 };
	 break;
	 case '/blog/index':
	 // This seo is for the blog's main landing page   (not for the individual blogs)
	 seoPageData = {
	 type: 'BlogIndexPage', //constants.PAGE_TYPE_BLOGINDEX,
	 personSeoCode: 'principal',
	 dateModified: Date(),
	 datePublished: Date(),
	 description: 'All posts written by Xxxxxx staff addressing ...',
	 keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	 path: pagePath,
	 slogan: '',
	 title: 'Blog about ...',
	 };
	 break;
	 case '/blog/?id':
	 seoPageData = {
	 type: 'BlogPage', //constants.PAGE_TYPE_BLOG,
	 personSeoCode: 'principal',
	 dateModified: Date(),
	 datePublished: Date(),
	 description: '',   //frontmatter.excerpt,
	 keywords: ['products', 'food', 'meat', 'vehicles', 'drugs', 'veterinary'],
	 path: pagePath,
	 pic: '',   //frontmatter.pic,   // '/images/mdxpages/chef-boyardee.jpg',
	 slogan: '',
	 title: '',   //frontmatter.title,
	 };
	 break;
	 case '/getstarted':
	 seoPageData = {
	 type: 'WebPage', //constants.PAGE_TYPE_WEB,
	 personSeoCode: 'principal',
	 dateModified: '',
	 datePublished: '',
	 description: '',
	 keywords: ['forms', 'html', 'builder'],
	 path: pagePath,
	 slogan: '',
	 title: '',
	 };
	 break;
       */
      /*
	 case '/process':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB,
	 personseocode: 'principal',
	 datemodified: '',
	 datepublished: '',
	 description: 'Some description.',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'some title...',
	 };
	 break;
	 case '/products':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB,
	 personseocode: 'principal',
	 datemodified: '',
	 datepublished: '',
	 description: 'Some description.',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'some title...',
	 };
	 break;
	 case '/myaccount':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB,
	 personseocode: 'principal',
	 datemodified: '',
	 datepublished: '',
	 description: 'software products developed by xxxxxx.',
	 keywords: ['vin', 'products', 'notification'],
	 path: pagePath,
	 slogan: '',
	 title: 'some title...',
	 };
	 break;
	 case '/services':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB,
	 personseocode: 'principal',
	 datemodified: '',
	 datepublished: '',
	 description: 'software products developed by xxxxxx.',
	 keywords: ['', '', '', '', ''],
	 path: pagePath,
	 slogan: '',
	 title: 'some title...',
	 };
	 break;
	 case '/technology':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB, // 'blogindexpage', 'blogpage', 'mdxpage', 'webpage', 'webindexpage', 'apppage'
	 personseocode: 'principal',
	 datemodified: '',
	 datepublished: '',
	 description: 'Some description.',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'some title...',
	 };
	 break;
       */
      /*
	 case '/partners':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB, // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'
	 personSeoCode: 'principal',
	 dateModified: '',
	 datePublished: '',
	 description:
	 'Partner with us - The top rated software development company.',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'Partner With Us To Develop Smart Technologies and Empower Business',
	 };
	 break;
	 case '/portfolio':
	 seoPageData = {
	 type: 'WebPage',  //constants.PAGE_TYPE_WEB, // 'BlogIndexPage', 'BlogPage', 'MDXPage', 'WebPage', 'WebIndexPage', 'AppPage'
	 personSeoCode: 'principal',
	 dateModified: '',
	 datePublished: '',
	 description:
	 'Sample of projects we developed to help business grow',
	 keywords: keywords,
	 path: pagePath,
	 slogan: '',
	 title: 'Portfolio Of Software Products',
	 };
	 break;
       */
    } // switch

    seoPageData['isCanonical'] = true;
  }









  
  
  //if (type == 'routeList') {
  //  return routeList;
  //}
  //else
  if (type == 'headerOptions') {
    return headerOptions;
  }
  else
    if (type == 'footerOptions') {
      return footerOptions;
    }
  else
    if (type == 'seoData') {
      return seoPageData;
    }


};
