import React, { useState, useEffect } from 'react';
import { Provider, observer } from 'mobx-react';
import { configure } from "mobx";
import { Grid, useTheme, Box } from '@mui/material';

import {
  useAppContext,
  Model,
  LaunchPage as Body,
  CommonAppLayout,
  MainTopbar,
  MinimalTopbar,
  //Submenubar,
} from 'clnt-common';

// Either use a LnchSidebar or Submenubar but not both
//import { SubmenubarList } from './SubmenubarList';

const model = new Model();                                       // <<< This differs across SPAs

// TO PREVENT DUMPING MESSAGES IN CONSOLE LOG
// Model.js?t=1700074595689:241 [MobX] Since strict-mode is enabled,
//  changing (observed) observable values without using an action is not allowed.
//  Tried to modify: ObservableObject@1.state
configure({
    enforceActions: "never",
})

export { HomeDtls };
const HomeDtls = props => {
  const theme = useTheme();

  const { appState, dispatch } = useAppContext();

  //const SubmenubarComp = <Submenubar buttonList={SubmenubarList(model, theme)} />

  useEffect(() => {
    const init = async () => {
      if ((appState.token || appState.fpid) && appState.upap) {
        await model.initRuntime(appState, dispatch);
	model.setInit(); // Forces a re-render as the state is changed from 'start' to 'init'
      }
      else {
	console.log('HomeDtls.jsx - Run useEffect - skip as there are no jwt and upap');
      }
    };
    init();
  },[appState.time, appState.token, appState.fpid, appState.upap]);

  if (!appState.upap) {
    console.log('HomeDtls.jsx - No upap available, show LOADING...');
    return <><h1> LOADING... </h1> <h2> {appState.emsg} </h2></>;
  }
  else {	
    //console.log('HomeDtls.jsx - Render');
    return (
	<Box>
	  <Provider store={model}>
	    <CommonAppLayout
              app={'LNCH'}
              Body={Body}
              Sidebar={null}
	      Submenubar={null}
              MainTopbar={MainTopbar}
              MinimalTopbar={MinimalTopbar}
	    /> {/* <<< This differs for each SPA */}
	  </Provider>
	</Box>
    );
  }

};


/*
FLOW

Model
  ctor: reference clientContext

await clientContextInstance.initRuntime(null, null); //jwt, upap);

await model.initRuntime(clientContextInstance);
  reset clientContext, jwt, upap, appConfig, appPalette, 
  reset myfetch's initRuntme() with model and jwt
  preset 'nav' item
  setup: serverio, repoCache, selection, editor, builder
  if not 'lnch' setup meta forms
  if 'form' setup user forms
  if 'lnch' setup lnch forms
    setupLaunchForms()
      repoCache.setupUserOrLaunchForms()
      setupUserFormSelection()
  
MainTopbar | MinimalTopbar

Body (LaunchPage)
  Header (removed until we have customizable header)
  LaunchItemListCtrn (form submission)
    Create 'p'
    FormCtnr p={p}
      parameters: form, items, instance, etc
      Inject css 
      Use query string supplied preset values to preset field values in the form
      extract ouot parents from form
      for each parents
        render children using MUIWidgetCtnr
      determine layout
      create an Empty Form
      create an actual Form with content
    
Footer

*/
